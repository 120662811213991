import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gotoTop from "./../assets/image/goto-top.png";
import SocialMediaLinks from "./SocialMediaLinks";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentpage, setCurrentPage] = useState();
  useEffect(() => {
    const hash = location.hash;
    const pathSegments = location.pathname.split("/");
    const currentPageName = pathSegments[pathSegments.length - 1];
    setCurrentPage(`${currentPageName}${hash}`);
  }, [location]);

  const goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <footer className="footerBg">
      <div className="footerTop relative">
        <div className="container">
          <div className="row paddingcls">
            <div className="col-md-3 footerTop-menu">
              <a
                className={currentpage === "#" ? "active" : ""}
                href="/product"
              >
                <h5>{t("product")}</h5>
              </a>
              <a
                className={currentpage === "product=hpv" ? "active" : ""}
                href="/ava?product=hpv"
              >
                {t("hpv")}
              </a>
              <a
                className={currentpage === "product=stis" ? "active" : ""}
                href="ava?product=stis"
              >
                {t("stis")}
              </a>
              <a
                className={currentpage === "product" ? "active" : ""}
                href="/product"
              >
                {t("how_it_works")}
              </a>
            </div>
            <div className="col-md-3 footerTop-menu">
              <a
                className={currentpage === "#" ? "active" : ""}
                href="/pedsupab"
              >
                <h5>{t("PEDSUPAB")}</h5>
              </a>
              <a
                className={currentpage === "#" ? "active" : ""}
                href="/pedsupab/blog/category/sex-counsel"
              >
                {t("Sex Counsel")}
              </a>
              <a
                className={currentpage === "#" ? "active" : ""}
                href="/pedsupab/blog/category/pillow-talk"
              >
                {t("Pillow Talk")}
              </a>
              <a
                className={currentpage === "#" ? "active" : ""}
                href="/sexualcare"
              >
                {t("Sexual Care")}
              </a>
              <a className={currentpage === "#" ? "active" : ""} href="/video">
                {t("Video")}
              </a>
            </div>
            <div className="col-md-3 footerTop-menu">
              <h5>{t("faqs")}</h5>
              <a
                className={currentpage === "faq#id_hpv" ? "active" : ""}
                href="/faq#id_hpv"
              >
                {t("hpv")}
              </a>
              <a
                className={currentpage === "faq#id_hpv" ? "active" : ""}
                href="/faq#id_hpv"
              >
                {t("stis")}
              </a>
              <a
                className={currentpage === "faq#id_hpv" ? "active" : ""}
                href="/faq#id_hpv"
              >
                {t("sexual_health")}
              </a>
            </div>
            <div className="col-md-3 footerTop-menu">
              <h5>{t("about_us")}</h5>
              <a
                className={
                  currentpage === "aboutus#id_overview" ? "active" : ""
                }
                href="/aboutus#id_overview"
              >
                {t("overview")}
              </a>
              <a
                className={currentpage === "aboutus#id_vision" ? "active" : ""}
                href="/aboutus#id_vision"
              >
                {t("vision")}
              </a>
              <a
                className={currentpage === "aboutus#id_mission" ? "active" : ""}
                href="/aboutus#id_mission"
              >
                {t("mission")}
              </a>
              <h5>{t("Affiliates")}</h5>
              <p>
                <a
                  href="https://healthspan.co.th/affiliates"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Affiliates")}
                </a>
              </p>
            </div>
          </div>
          <SocialMediaLinks />
        </div>
      </div>
      <div className="footerBottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 footerLft">
              <Link>
                <span className="dt">.</span> {t("privacy_policy")}
              </Link>
              &nbsp;&nbsp;
              <Link>
                <span className="dt">.</span> {t("terms_and_conditions")}
              </Link>
            </div>
            <div className="col-md-6 footerRgt">
              <span>{t("follow_us")}: </span>
              <a
                href="https://www.facebook.com/pedsupab.official"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/pedsupab.official/?igsh=a2ZzdDN6dncyNHYw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.youtube.com/@Healthspanofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.tiktok.com/@pedsupab.official"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-tiktok"></i>
              </a>
              <a
                href="https://x.com/pedsupab?mx=2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://line.me/R/ti/p/@723pzgzk?oat_content=url"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-line"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
