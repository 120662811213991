import React, { useState } from "react";
import hline from "../assets/image/hline.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FrequestAsk = () => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState();

  return (
    <div className="faq-wrap">
      <h1 className="text-center p-4 my-6 font-semibold">
        <span className="text-[#d3b05b]">
          {t("frequently_asked").toUpperCase()}
        </span>
        {"  "}
        <span className="text-[#61422c]">
          {t("questions").toUpperCase()} ({t("faqs").toUpperCase()})
        </span>
      </h1>
      <div className="row imagecontainer">
        <div
          className={`col-md-${toggle ? (toggle === 1 ? "6" : "3") : "4"
            } image1 common-block`}
          onClick={() => setToggle(toggle === 1 ? null : 1)}
        >
          <div className="flex items-end">
            <img src={hline} alt="" className="line" />
            <h3 style={{ color: '#ffd660', fontWeight: '700', textAlign: 'left', fontSize: '2.5rem' }}>{t("hpv")}</h3>
          </div>
         
            <div className={`sec-content slide`}>
              <div className="mt-6 lg:mt-20">
                <span className="descrip para-txt">
                  {t("frequently_asked_hpv")}
                </span>
                <Link className="btn seemore" to="/faq">
                  {t("see_more")}
                </Link>
              </div>
            </div>
          
        </div>
        <div
          className={`col-md-${toggle ? (toggle === 2 ? "6" : "3") : "4"
            } image2 common-block`}
          onClick={() => setToggle(toggle === 2 ? null : 2)}
        >
          <div className="flex items-end">
            <img src={hline} alt="" className="line" />
            <h3 style={{ color: '#ffd660', fontWeight: '700', textAlign: 'left', fontSize: '2.5rem' }}>{t("stis")}</h3>
          </div>
            <div className={`sec-content slide`}>
              <div className="mt-6 lg:mt-20">
                <span className="descrip2 para-txt">
                  {t("frequently_asked_stis")}
                </span>
                <Link className="btn seemore" to="/faq">
                  {t("see_more")}
                </Link>
              </div>
            </div>
        </div>
        <div
          className={`col-md-${toggle ? (toggle === 3 ? "6" : "3") : "4"
            } image3 common-block`}
          onClick={() => setToggle(toggle === 3 ? null : 3)}
        >
          <div className="flex items-end">
            <img src={hline} alt="" className="line" />
            <h3 style={{ color: '#ffd660', fontWeight: '700', textAlign: 'left', fontSize: '2.5rem' }}>{t("sexual_health")}</h3>
          </div>
            <div className={`sec-content slide`}>
              <div className="mt-6 lg:mt-20">
                <span className="descrip3 para-txt">
                  {t("frequently_asked_sexual_health")}
                </span>
                <Link className="btn seemore" to="/faq">
                  {t("see_more")}
                </Link>
              </div>
            </div>
        </div>
      </div>
    </div >
  );
};

export default FrequestAsk;
