import React, { useState, useEffect } from "react";
import axios from 'axios';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import ArticlesCard from "./ArticlesCard";
import clientConfig from '../client-config';

const Articles = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [authToken, setAuthToken] = useState('');

  useEffect(() => {
    const getAuthToken = async () => {
      try {
        const response = await axios.post(`${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`, {
          username: 'admin',
          password: 'admin@2024'
        });
        setAuthToken(response.data.token);
      } catch (err) {
        console.error('Error fetching auth token:', err);
        setError('Error fetching auth token');
      }
    };

    const fetchPostsAndCategories = async () => {
      setLoading(true);
      try {
        if (!authToken) {
          await getAuthToken();
        }

        // Fetch posts
        const postsResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/posts`);
        const posts = postsResponse.data;

        // Fetch categories
        const categoriesResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/categories`);
        const categories = categoriesResponse.data.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});

        // Fetch media for posts
        const postsWithMedia = await Promise.all(posts.map(async (post) => {
          if (post.featured_media) {
            try {
              const mediaResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`, {
                headers: {
                  'Authorization': `Bearer ${authToken}` // Include the token if required
                }
              });
              post.featured_media_url = mediaResponse.data.source_url;
            } catch (mediaError) {
              console.error(`Error fetching media for post ${post.id}:`, mediaError);
              post.featured_media_url = 'default_image_url'; // Use a default image if fetching media fails
            }
          } else {
            post.featured_media_url = 'default_image_url'; // You can set a default image URL if needed
          }
          return post;
        }));

        setPosts(postsWithMedia);
        setCategories(categories);
      } catch (err) {
        console.error('Error:', err);
        setError('Error fetching posts and categories');
      } finally {
        setLoading(false);
      }
    };

    fetchPostsAndCategories();
  }, [authToken]);

  return (
    <div className="defpadding nppadding_bottom">
      <div className="feature-three-blogs-w feature-blog">
        <h2 className="recent_article">
          <span className="recent">{t("recent")}</span>{" "}
          <span className="article">{t("articles")}</span>
        </h2>
        {error && <div className="alert alert-danger">{error}</div>}
        {loading && <div>Loading...</div>}
        {!loading && !error && (
          <OwlCarousel
            className="owl-theme"
            loop
            items={3}
            margin={16}
            autoplay={true}
            autoplayTimeout={5000}
            nav
            responsive={{
              1: {
                items: 1,
              },
              767: {
                items: 2,
              },
              1025: {
                items: 3,
              },
            }}
          >
            {posts.map((post) => (
              <ArticlesCard key={post.id} post={post} categories={categories} />
            ))}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
};

export default Articles;
