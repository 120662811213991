import { Card, Container, Nav } from "react-bootstrap";
import Footer from "../footer";
import Header from "../header";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const videos = [
  {
    id: 8,
    youtubeId: "ZuAMIxeFSNQ",
    description:
      "สัมภาษณ์พิเศษจากน้องๆYoung & Fabulous Thailand กับการคุยเรื่องเพศอย่างเปิดเผย| เพศกล้าพูด",
  },
  {
    id: 9,
    youtubeId: "h0o_EPna1gA",
    description: "เหรียญโสเภณี เอาไว้แลกบริการทางเพศได้ l เพศไปเรื่อย EP.2",
  },
  {
    id: 10,
    youtubeId: "aCUcTYv65GA",
    description:
      "ช่องคลอดหดเกร็ง (จิ๋มล็อก) รู้สึกเจ็บเวลามีเพศสัมพันธ์ l เพศต้องพูด EP.5",
  },
  {
    id: 11,
    youtubeId: "s0xVpx57C0c",
    description:
      "สัญญาณเตือนของความสัมพันธ์ Red flag & Green flag l เพศต้องพูด (EP.พิเศษ) TALK TO PEACH",
  },
  {
    id: 12,
    youtubeId: "tKB00XYaSfo",
    description:
      "เซ็กส์...ในชีวิตจริงไม่เหมือนในจินตนาการ | เพศต้องพูด EP.1 @GenitiqueClinic",
  },
  {
    id: 1,
    youtubeId: "XKsFV7v99Ag",
    description: "ภาพรวมในปี 2023 กันดีกว่าว่าเจออะไรกันมาบ้าง",
  },
  // {
  //   id: 2,
  //   youtubeId: "8QmXqKuXYlY",
  //   description: "สัพเพเหระ Ep.1 ย้อนวัยตอบคำถามวิชา 'เพศศึกษา' | เพศสุภาพ",
  // },
  {
    id: 3,
    youtubeId: "tKB00XYaSfo",
    description:
      "เซ็กส์...ในชีวิตจริงไม่เหมือนในจินตนาการ | เพศต้องพูดเพศสุภาพ EP.1",
  },
  {
    id: 4,
    youtubeId: "Wb95n-3_YrM",
    description:
      "อย่าให้โรคทำลายรัก..ความจริงของโรคติดต่อทางเพศสัมพันธ์ | เพศต้องพูดเพศสุภาพ EP.2",
  },
  {
    id: 5,
    youtubeId: "j4diBDUz0oY",
    description:
      "คุมกำเนิดมีวิธีไหนบ้าง แบบไหนพัง แบบไหนปัง มีคำตอบ | หมอขาหนูอยากรู้",
  },
  {
    id: 6,
    youtubeId: "gUhbGyM1TuQ",
    description:
      "เป็นวัยทองทำยังไง ส่งผลกระทบอะไรไหม รีเช็กยังไงว่าเป็นวัยทองยัง | หมอขาหนูอยากรู้",
  },
  {
    id: 7,
    youtubeId: "BHoFeE3rM7Q",
    description: "เพศศึกษาจำเป็นไหม ? | เพศสุภาพ",
  },
];

export default function Videos() {
  const { videoId } = useParams();
  const { t } = useTranslation();

  const currentVideo =
    videos.find((video) => video.id === Number(videoId)) ?? videos[0];

  return (
    <main className="main">
      <Header />

      <div className="kn-dtls-page-content pt-0">
        <section className="tag_wrapper max-sm:px-4 -mx-12 lg:-mx-96">
          <Container>
            <Card className="tagcard">
              <Nav onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}>
                <Nav.Item>Tag:</Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="#SAFESEX">#SAFESEX</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="##LGBT">##LGBT</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="#LOVE">#LOVE</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="#คู่รัก">#คู่รัก</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="#โรคติดต่อ">#โรคติดต่อ</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card>
          </Container>
        </section>

        <section className="popular-section popular_video mb-5 max-h-full">
          <Container>
            <div className="flex justify-between gap-3">
              <div className="w-full">
                <h1 className="heading !text-3xl !text-left border-b-8 !mt-0 !mb-10 border-[#9e015d]">
                  {t("videos")}
                </h1>
                <Card className="video-card-wrap common-content">
                  <iframe
                    src={`https://www.youtube.com/embed/${currentVideo.youtubeId}`}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    title={currentVideo.description}
                    height={500}
                  />
                </Card>
                <h2 className="heading !text-2xl !mb-4 !mt-10 !sm:text-left">
                  {t("latest_on_pedsupab")}
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                  {videos
                    .filter((video) => video.id !== currentVideo.id)
                    .map((video) => (
                      <Card>
                        <Link
                          to={`/video/${video.id}`}
                          className="flex flex-col gap-2"
                        >
                          <img
                            src={`https://img.youtube.com/vi/${video.youtubeId}/mqdefault.jpg`}
                            width={120}
                            height={90}
                            alt={video.description}
                            className="rounded"
                          />
                          <Card.Title className="w-full !text-base !text-gray-700 text-left">
                            {video.description.slice(0, 60) +
                              (video.description.length > 60 ? "..." : "")}
                          </Card.Title>
                        </Link>
                      </Card>
                    ))}
                </div>
              </div>

              <div className="max-lg:hidden lg:w-[28rem] padding-left0 mt-24">
                <div className="h-100 d-flex flex-column column-custom-justify">
                  <h2 className="heading !text-2xl !mt-0 !mb-4 !text-left border-b-4 border-[#9e015d]">
                    {t("latest")}
                  </h2>
                  <div className="flex-1">
                    {videos
                      .filter((video) => video.id !== currentVideo.id)
                      .map((video) => (
                        <Card>
                          <Link
                            to={`/video/${video.id}`}
                            className="flex items-start gap-2"
                          >
                            <img
                              src={`https://img.youtube.com/vi/${video.youtubeId}/mqdefault.jpg`}
                              width={120}
                              height={90}
                              alt={video.description}
                              className="rounded !w-40 !h-24"
                            />
                            <Card.Title className="w-full !text-base !text-gray-700 text-left">
                              {video.description.slice(0, 60) +
                                (video.description.length > 60 ? "..." : "")}
                            </Card.Title>
                          </Link>
                        </Card>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Footer />
    </main>
  );
}
